import React, { Component } from 'react';

import { Grid } from '@material-ui/core';



export default class MaxPage extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
          <Grid container spacing={0}>
          </Grid>
        )
    }
}
