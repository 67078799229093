import React, { Component } from 'react';


export default class VisualsPage extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
          <div>
          </div>
        )
    }
}
