import React, { Component } from 'react';


export default class ContactPage extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
          <h1>Contact</h1>
        )
    }
}
